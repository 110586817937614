import React from 'react'
import SVG from 'react-inlinesvg'
import gatsbySVG from '../../assets/Gatsby-Logo.svg'
import { Text } from 'theme-ui'

const styles = {
  link: {
    display: `inline-flex`,
    color: `heading`,
    fontWeight: `medium`,
    ':visited': {
      color: `heading`
    },
    svg: {
      height: 24,
      ml: 2
    }
  }
}

const PoweredByGatsby = () => (
  <Text
    sx={styles.link}
  >
    Powered By
    {gatsbySVG && <SVG src={gatsbySVG} />}
  </Text>
)

export default PoweredByGatsby
